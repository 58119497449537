<template>
  <!--  <div class="container">-->
  <!--    <transition name="fade" mode="out-in">-->
  <!--      <base-loader v-if="!list.length" />-->
  <!--      <div v-else class="ssl">-->
  <!--        <div class="ssl-info">-->
  <!--          <page-title>-->
  <!--            {{ title }}-->
  <!--          </page-title>-->
  <!--          <div v-if="this.$route.name === 'sslMy'">-->
  <!--            <base-button :to="{ name: 'sslBuy' }">-->
  <!--              {{ buy_ssl }}-->
  <!--            </base-button>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div class="ssl-info__create"></div>-->
  <!--        <tabs :list="sslNav" class="ssl__tabs medium-title">-->
  <!--          <template v-slot:item="{ item }">-->
  <!--            <router-link-->
  <!--              class="ssl__tabs-element"-->
  <!--              exact-active-class="active"-->
  <!--              tag="div"-->
  <!--              :to="item.to"-->
  <!--            >-->
  <!--              {{ item.title }}-->
  <!--            </router-link>-->
  <!--          </template>-->
  <!--        </tabs>-->
  <!--        <div class="ssl__content">-->
  <!--          <transition name="slide-fade">-->
  <!--            <router-view :loading="loading" :dataset="list"></router-view>-->
  <!--          </transition>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </transition>-->
  <!--  </div>-->

  <div class="ssl">
    <div class="ssl-info">
      <div class="ssl-info__content">
        <page-title class="ssl-info__title">
          {{ $t('title') }}
        </page-title>
        <div v-if="list.length" class="ssl-mode">
          <plain-button
            icon="grid"
            class="ssl-mode__btn"
            watch-active
            :active="mode === 'grid'"
            @click="setMode('grid')"
          />
          <plain-button
            icon="list"
            class="ssl-mode__btn"
            watch-active
            :active="mode === 'table'"
            @click="setMode('table')"
          />
        </div>
      </div>
      <div v-if="pricelistsAvailable" :class="{ visible: list.length }" class="ssl-info__create">
        <base-button :to="{ name: 'sslOrder' }">
          {{ $t('createServer') }}
        </base-button>
      </div>
    </div>
    <transition name="fade" mode="out-in" appear>
      <div v-if="isLoading" class="ssl-info__loader">
        <base-loader class="ssl-info__loader-item" />
      </div>
      <div v-else class="ssl-content">
        <base-alert
          v-if="isServerError"
          :title="$t('errors.serverError.title')"
          icon="warn"
          class="ssl-info__alert"
        >
          {{ $t('errors.serverError.text') }}
        </base-alert>
        <base-empty
          v-else-if="!list.length && !isServerError"
          :title="$t('empty.title')"
          :text="$t('empty.text')"
          :link="{ title: $t('createServer'), to: { name: 'sslOrder' } }"
          class="ssl-info__empty"
        />
        <div v-else class="ssl-content__list">
          <transition name="fade" mode="out-in">
            <component :is="modeComponents[mode]" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import storeMixin from '../../mixins/index';
import TariffsGrid from '@/layouts/SSL/pages/Main/components/TariffsGrid';
import TariffsTable from '@/layouts/SSL/pages/Main/components/TariffsTable';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty';
import BaseAlert from '@/components/BaseAlert/BaseAlert';
import priceListMixin from '@/mixins/priceListMixin';
export default {
  name: 'SSlMain',
  components: {
    TariffsGrid,
    TariffsTable,
    BaseEmpty,
    BaseAlert,
  },
  mixins: [storeMixin, priceListMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modeComponents: {
        grid: TariffsGrid,
        table: TariffsTable,
      },
      // isLoading: true,
      pricelistsAvailable: false,
      path: 'moduleSSL/moduleSSLOrder',
      // buy_ssl: this.$t('buy_ssl'),
      // title: this.$t('label'),
      isServerError: false,
      // nav: [
      //   {
      //     title: this.$t('nav.my_ssl'),
      //     to: { name: 'sslMy' },
      //     access: { name: 'myList', value: true },
      //   },
      //   {
      //     title: this.$t('nav.buy_ssl'),
      //     to: { name: 'sslBuy' },
      //   },
      // ],
    };
  },
  computed: {
    mode() {
      return this.$store.state.moduleSSL.mode;
    },
    sslNav() {
      return this.nav.filter(
        i => !i.access || (i.access && this[i.access.name] === i.access.value)
      );
    },
    list() {
      return this.$store.state.moduleSSL.list;
    },
    myList() {
      return this.list.length > 0;
    },
  },
  mounted() {
    if (!this.list.length) {
      this.fetchList().catch(e => {
        if (e.status && e.status === 520) this.isServerError = true;
      });
    }
    this.fetchPricelist(this.path);
  },
  methods: {
    setMode(val) {
      this.$store.commit('moduleSSL/SET_MODE', val);
    },
  },
};
</script>

<i18n>
{
  "ru":{
    "createServer": "Купить сертификат",
    "title": "SSL Сертификаты",
    "nav": {
      "buy_ssl": "Купить сертификат",
      "my_ssl": "Мои сертификаты"
    },
    "empty": {
      "title": "На данный момент у вас нет ни одного SSL сертификата",
      "text": "SSL сертификат - это аналог цифрового «удостоверения личности» вашего сайта: на самом базовом уровне он подтверждает его подлинность, а на более продвинутых – принадлежность конкретному администратору, либо организации и удостоверяет ее легитимность. Кроме того, он обеспечивает стойкое шифрование при передаче данных от пользователя к ресурсу и наоборот."
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.ssl {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
  &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb, center);
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        margin-right: 1.5rem;
        flex: 1 1 auto;
      }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }

    &__create {
      flex: 0 0 auto;
      margin-top: 1.5rem;
      max-width: 200px;
      visibility: hidden;

      &.visible {
        visibility: visible;
      }

      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
    }
  }

  &-mode {
    flexy(flex-end, center);
    margin-left: auto;

    +breakpoint(sm-and-up) {
      justify-content: flex-start;
      margin-left: 1.5rem;
    }

    &__btn {
      & + & {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
